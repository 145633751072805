import React from 'react';

const DeveloperInfoPage: React.FC = () => {
	return (
		<div>
			<h1>Welcome to the Developer Info Page</h1>
		</div>
	);
};

export default DeveloperInfoPage;
